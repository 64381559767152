import clsx from 'clsx'
import React, { FC } from 'react'

import { BannerLinkItem } from '@/entities/component/api/interfaces/get-banner-links-response.interface'
import { PropsWithClassName } from '@/shared/interfaces'
import { PrimaryButton } from '@/shared/ui/button'

import styles from './desktop-links-banner.styles.module.scss'

interface DesktopLinksBannerProps extends PropsWithClassName {
  links: BannerLinkItem[]
}

export const DesktopLinksBanner: FC<DesktopLinksBannerProps> = ({ links, className }) => {
  return (
    <section className={clsx(styles.container, className)}>
      <ul className={styles.links}>
        {links.map((bannerLink) => (
          <li
            key={bannerLink.link.href}
            className={styles.link}
          >
            <PrimaryButton
              className={styles.linkButton}
              href={bannerLink.link.href}
              target={bannerLink.link.target}
            >
              {bannerLink.link.title}
            </PrimaryButton>
          </li>
        ))}
      </ul>
    </section>
  )
}
