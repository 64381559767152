import Select, { BaseOptionType } from 'antd/lib/select'
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { FlattenOptionData } from 'rc-select/lib/interface'
import { FC, memo, ReactElement, ReactNode, useEffect, useMemo } from 'react'

import { Page } from '@/entities/collection/api/interfaces/get-page-response.interface'
import { Locale } from '@/entities/locale/api/interfaces/get-locale-response.interface'
import { usePageProps } from '@/pages/_app.page'
import { env } from '@/shared/env'
import { useLocale } from '@/shared/hooks/use-locale.hook'
import { PropsWithClassName } from '@/shared/interfaces'

import styles from './language-picker.styles.module.scss'

const languagesMap = new Map<string, string>([
  ['en', 'gb'],
  ['uk', 'ua'],
])

interface LanguagePickerProps extends PropsWithClassName {}

export const LanguagePicker: FC<LanguagePickerProps> = ({ className }) => {
  const { locales, page } = usePageProps()
  const currentLocale = useLocale()

  const options = useMemo(() => locales.filter((locale) => locale.code !== currentLocale).map(localeToSelectOption), [locales, currentLocale])

  // NOTE: any coz of eslint error for defaultValue of Select
  const defaultValue = useMemo<any>(
    () => localeToSelectOption(locales.find((locale) => locale.code === currentLocale)!),
    [currentLocale, locales],
  )

  useEffect(() => {
    document.documentElement.setAttribute('lang', currentLocale)
  }, [currentLocale])

  return (
    <Select<any, SelectOption>
      aria-label="country-flag"
      className={clsx(styles.select, className)}
      defaultValue={defaultValue}
      fieldNames={{ value: 'code' }}
      optionRender={optionRender(page.attributes)}
      options={options}
      popupClassName={styles.popup}
      rootClassName={styles.container}
      suffixIcon={null}
      variant="borderless"
    />
  )
}

function optionRender(currentPage: Page['attributes']) {
  return function (option: FlattenOptionData<BaseOptionType>): ReactNode {
    const locale = option.value!.toString()

    const src = getLocaleIconUrl(locale)
    const localePage =
      currentPage.localizations?.data.find((localizedPage) => localizedPage.attributes.locale === locale)?.attributes ?? currentPage

    const slugHref = localePage.href
    const isDefaultLocale = Reflect.get(option.data, 'isDefault')

    const href = {
      pathname: isDefaultLocale ? (localePage.isDefault ? '/' : slugHref) : '/'.concat(locale, slugHref),
    }

    return (
      <Link href={href}>
        <Image
          alt={locale}
          height={100}
          src={src}
          width={210}
        />
      </Link>
    )
  }
}

const LocaleLabel: FC<{ locale: Locale }> = memo(({ locale }) => {
  const src = getLocaleIconUrl(locale.code)

  return (
    <Image
      alt={locale.name}
      height={100}
      src={src}
      width={210}
    />
  )
})

function localeToSelectOption(locale: Locale): SelectOption {
  return {
    ...locale,
    label: <LocaleLabel locale={locale} />,
  }
}

function getLocaleIconUrl(code: string): string {
  return new URL(`${languagesMap.get(code) ?? code}.svg`, env.get('API_FLAGS_URL')).toString()
}

interface SelectOption extends BaseOptionType, Locale {
  label: ReactElement
}
