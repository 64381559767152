import { MenuOutlined } from '@ant-design/icons'
import Button from 'antd/lib/button'
import Drawer from 'antd/lib/drawer'
import clsx from 'clsx'
import Link from 'next/link'
import { FC, MouseEvent, KeyboardEvent } from 'react'

import { NavigationPages } from '@/entities/component/api/interfaces/get-navigation-response.interface'
import { useParamsLocale } from '@/shared/hooks/use-params-locale.hook'
import { useToggle } from '@/shared/hooks/use-toggle.hook'
import { PropsWithClassName } from '@/shared/interfaces'

import styles from './mobile-menu.styles.module.scss'

interface MobileMenuProps extends PropsWithClassName {
  pages: NavigationPages['data']
}

export const MobileMenu: FC<MobileMenuProps> = ({ pages, className }) => {
  const paramsLocale = useParamsLocale()

  const [isOpen, toggleIsOpen, setIsOpen] = useToggle()
  const closeDrawer = (e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation()
    setIsOpen(false)
  }

  return (
    <>
      <Button
        className={clsx(styles.button, className)}
        onClick={toggleIsOpen}
        type="text"
      >
        <MenuOutlined className={styles.icon} />
      </Button>
      <Drawer
        classNames={{ content: styles.drawer }}
        onClose={closeDrawer}
        open={isOpen}
        placement="right"
      >
        <ul className={styles.links}>
          {pages.map((page) => {
            const href = paramsLocale ? '/'.concat(paramsLocale, page.attributes.href) : page.attributes.isDefault ? '/' : page.attributes.href

            return (
              <li
                key={page.attributes.href}
                className={styles.link}
              >
                <Link
                  href={href}
                  onClick={toggleIsOpen}
                >
                  {page.attributes.title}
                </Link>
              </li>
            )
          })}
        </ul>
      </Drawer>
    </>
  )
}
