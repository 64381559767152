import Grid from 'antd/lib/grid'
import clsx from 'clsx'
import React, { FC } from 'react'

import { DesktopLinksBanner } from '@/features/links-banner/ui/desktop-links-banner'
import { MobileLinksBanner } from '@/features/links-banner/ui/mobile-links-banner'
import { usePageProps } from '@/pages/_app.page'
import { PropsWithClassName } from '@/shared/interfaces'

import styles from './links-banner.styles.module.scss'

export const LinksBanner: FC<PropsWithClassName> = ({ className }) => {
  const { bannerLinks = [] } = usePageProps()
  const { lg } = Grid.useBreakpoint()

  if (!bannerLinks.length) {
    return null
  }

  return lg ? (
    <DesktopLinksBanner
      className={clsx(styles.container, className)}
      links={bannerLinks}
    />
  ) : (
    <MobileLinksBanner
      className={clsx(styles.container, className)}
      links={bannerLinks}
    />
  )
}
