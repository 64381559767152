import { CloudDownloadOutlined } from '@ant-design/icons'
import Button, { ButtonProps } from 'antd/lib/button'
import clsx from 'clsx'
import { FC } from 'react'

import styles from './download-app-button.styles.module.scss'

interface DownloadAppButtonProps extends Omit<ButtonProps, 'type' | 'download' | 'icon' | 'disabled' | 'loading' | 'children' | 'href'> {
  filename?: string
  href: string
}

export const DownloadAppButton: FC<DownloadAppButtonProps> = ({ size = 'middle', title = 'Download', className, ...props }) => {
  // const [isLoading, toggleIsLoading] = useToggle()
  //
  // const onClick = async () => {
  //   try {
  //     toggleIsLoading()
  //     await fetch(props.href)
  //   } catch (error) {
  //     console.warn(error)
  //   } finally {
  //     toggleIsLoading()
  //   }
  // }

  return (
    <Button
      {...props}
      className={clsx(styles.container, className)}
      classNames={{ icon: styles.icon }}
      download={props.filename}
      icon={<CloudDownloadOutlined size={40} />}
      size={size}
      title={title}
      type="default"
      // onClick={onClick}
      // disabled={isLoading}
      // loading={isLoading}
    >
      {title}
    </Button>
  )
}
