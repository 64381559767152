import clsx from 'clsx'
import { FC } from 'react'

import { LinksBanner } from '@/features/links-banner'
import { PropsWithClassName } from '@/shared/interfaces'

import styles from './aside.styles.module.scss'

interface AsideProps extends PropsWithClassName {}

export const Aside: FC<AsideProps> = ({ className }) => {
  return (
    <aside className={clsx(styles.container, className)}>
      <LinksBanner className={styles.banner} />
    </aside>
  )
}
