import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'

import { DownloadAppButton } from '@/features/download-app-button'
import { LanguagePicker } from '@/features/language-picker'
import { Navigation } from '@/features/navigation'
import { usePageProps } from '@/pages/_app.page'
import { useParamsLocale } from '@/shared/hooks/use-params-locale.hook'
import { PropsWithClassName } from '@/shared/interfaces'

import styles from './header.styles.module.scss'

interface NavigationProps extends PropsWithClassName {}

export const Header: FC<NavigationProps> = ({ className }) => {
  const { navigation, global } = usePageProps()
  const paramsLocale = useParamsLocale()

  if (!navigation) {
    return null
  }

  const page = navigation.pages.data.find((page) => page.attributes.isDefault)!
  const href = paramsLocale ? '/'.concat(paramsLocale, page.attributes.href) : page.attributes.isDefault ? '/' : page.attributes.href

  return (
    <section className={clsx(styles.container, className)}>
      <Link href={href}>
        <Image
          alt={navigation.logo.data.attributes.alternativeText}
          className={styles.logo}
          height={navigation.logo.data.attributes.height}
          src={navigation.logo.data.attributes.url}
          width={navigation.logo.data.attributes.width}
        />
      </Link>
      <Navigation className={styles.navigation} />
      {global.downloadAppButton && (
        <DownloadAppButton
          className={styles.download}
          filename={global.downloadAppButton.filename}
          href={global.downloadAppButton.href}
          target={global.downloadAppButton.target}
          title={global.downloadAppButton.title}
        />
      )}
      <LanguagePicker className={styles.languagePicker} />
    </section>
  )
}
