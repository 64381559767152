import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { usePageProps } from '@/pages/_app.page'

export function useParamsLocale() {
  const { locales } = usePageProps()

  const { query } = useRouter()
  const paramsLocale = query.localeOrSlug

  return useMemo(() => locales.find((locale) => locale.code === paramsLocale)?.code, [locales, paramsLocale])
}
