import clsx from 'clsx'
import Link from 'next/link'
import { FC } from 'react'

import { NavigationPages } from '@/entities/component/api/interfaces/get-navigation-response.interface'
import { useParamsLocale } from '@/shared/hooks/use-params-locale.hook'
import { PropsWithClassName } from '@/shared/interfaces'

import styles from './desktop-menu.styles.module.scss'

interface DesktopMenuProps extends PropsWithClassName {
  pages: NavigationPages['data']
}

export const DesktopMenu: FC<DesktopMenuProps> = ({ pages, className }) => {
  const paramsLocale = useParamsLocale()

  return (
    <ul className={clsx(styles.container, className)}>
      {pages.map((page) => {
        const href = paramsLocale ? '/'.concat(paramsLocale, page.attributes.href) : page.attributes.isDefault ? '/' : page.attributes.href

        return (
          <li
            key={page.attributes.href}
            className={styles.link}
          >
            <Link href={href}>{page.attributes.title}</Link>
          </li>
        )
      })}
    </ul>
  )
}
