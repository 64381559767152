import './globals.css'
import '@total-typescript/ts-reset'

import { Roboto } from 'next/font/google'
import { useRouter } from 'next/router'
import { createContext, ElementType, useContext } from 'react'

import { Page } from '@/entities/collection/api/interfaces/get-page-response.interface'
import { BannerLinkItem } from '@/entities/component/api/interfaces/get-banner-links-response.interface'
import { Global } from '@/entities/component/api/interfaces/get-global-response.interface'
import { NavigationAttributes } from '@/entities/component/api/interfaces/get-navigation-response.interface'
import { Locale } from '@/entities/locale/api/interfaces/get-locale-response.interface'
import { Providers } from '@/infrastructure/providers'
import { SlugPageLayout } from '@/shared/ui/slug-page-layout'

interface AppProps {
  Component: ElementType
  pageProps: AnyPageContext
}

const errorPages = ['/404', '/500']

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter()

  const isErrorPage = errorPages.includes(router.pathname)
  // const Layout: ElementType = isErrorPage ? Fragment : SlugPageLayout

  return (
    <>
      <style
        global
        jsx
      >
        {`
          :root {
            --font-family: ${font.style.fontFamily};
          }
        `}
      </style>
      <pageContext.Provider value={pageProps}>
        <Providers>
          {isErrorPage ? (
            <Component />
          ) : (
            <SlugPageLayout>
              <Component />
            </SlugPageLayout>
          )}
          {/* <Layout>
            <Component />
          </Layout>*/}
        </Providers>
      </pageContext.Provider>
    </>
  )
}

const font = Roboto({
  weight: ['100', '300', '400', '700', '900'],
  subsets: ['latin'],
  variable: '--font-family',
  display: 'swap',
})

export interface AnyPageContext {
  page: Page
  locales: Locale[]
  navigation: NavigationAttributes
  bannerLinks: BannerLinkItem[]
  global: Global
}

const pageContext = createContext<AnyPageContext>({
  page: null as unknown as Page,
  locales: [],
  navigation: null as unknown as NavigationAttributes,
  bannerLinks: [],
  global: null as unknown as Global,
})

export const usePageProps = () => useContext(pageContext)
