import Grid from 'antd/lib/grid'
import { FC } from 'react'

import { usePageProps } from '@/pages/_app.page'
import { PropsWithClassName } from '@/shared/interfaces'

import { DesktopMenu } from './ui/desktop-menu'
import { MobileMenu } from './ui/mobile-menu'

interface NavigationProps extends PropsWithClassName {}

export const Navigation: FC<NavigationProps> = ({ className }) => {
  const { navigation } = usePageProps()
  const { lg } = Grid.useBreakpoint()

  return <nav className={className}>{lg ? <DesktopMenu pages={navigation.pages.data} /> : <MobileMenu pages={navigation.pages.data} />}</nav>
}
