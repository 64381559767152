import Button, { ButtonProps } from 'antd/lib/button'
import { FC } from 'react'

export const PrimaryButton: FC<Omit<ButtonProps, 'type'>> = ({ children, ...props }) => {
  return (
    <Button
      type="primary"
      {...props}
    >
      {children}
    </Button>
  )
}
